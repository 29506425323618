<template>
    <div class="statistics" v-if="loaded">
        <div class="columns">
            <div class="column">
                <h3>Kandidaten</h3>
                <p>Aantal kandidaten: {{stats.candidates}}</p>
                <p>Eerste score: {{stats.first}}</p>
                <p>Laatste score: {{stats.last}}</p>
            </div>
            <div class="column">
                <h3>Tijdvak</h3>
                <input type="text" name="van" v-model="fromDate" placeholder="dd-mm-jjjj"/>
                &nbsp;tot&nbsp;
                <input type="text" name="tot" v-model="toDate" placeholder="dd-mm-jjjj"/>
                <p><button type="button" class="stats-button" @click="getStats">Verwerken</button></p>
            </div>
        </div>

        <div v-if="stats.candidates > 0">
        <h3>Totaalscores</h3>
        <table class="table">
            <thead>
            <tr>
                <th></th>
                <th>Min</th>
                <th>Max</th>
                <th>Gemiddelde</th>
                <th>Variantie</th>
                <th>St. dev.</th>
            </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Totaal</td>
                    <td>{{stats.total.stats.min}}</td>
                    <td>{{stats.total.stats.max}}</td>
                    <td>{{stats.total.stats.mean}}</td>
                    <td>{{stats.total.stats.variance}}</td>
                    <td>{{stats.total.stats.stdev}}</td>
                </tr>
            </tbody>
        </table>
        <h3>Scores per rol</h3>
        <table class="table">
            <thead>
            <tr>
                <th></th>
                <th>Aantal</th>
                <th>Min</th>
                <th>Max</th>
                <th>Gemiddelde</th>
                <th>Variantie</th>
                <th>Std.</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="data in stats.roles">
                    <td>{{data.name}}</td>
                    <td>{{data.stats.count}}</td>
                    <td>{{data.stats.min}}</td>
                    <td>{{data.stats.max}}</td>
                    <td>{{data.stats.mean}}</td>
                    <td>{{data.stats.variance}}</td>
                    <td>{{data.stats.stdev}}</td>
                </tr>
            </tbody>
        </table>
       <h3>Scores per sectie</h3>
        <table class="table">
            <thead>
            <tr>
                <th></th>
                <th>Aant.</th>
                <th>Min</th>
                <th>Max</th>
                <th>Gem.</th>
                <th>Var.</th>
                <th>Std.</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="data in stats.sections">
                    <td>{{data.name}}</td>
                    <td>{{data.stats.count}}</td>
                    <td>{{data.stats.min}}</td>
                    <td>{{data.stats.max}}</td>
                    <td>{{data.stats.mean}}</td>
                    <td>{{data.stats.variance}}</td>
                    <td>{{data.stats.stdev}}</td>
                    <td></td>
                </tr>
            </tbody>
        </table>

        <h3>Scores per competentie</h3>
        <table class="table">
            <thead>
            <tr>
                <th></th>
                <th>Aant.</th>
                <th>Min</th>
                <th>Max</th>
                <th>Gem.</th>
                <th>Var.</th>
                <th>Std.</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="(data) in stats.competences">
                    <td>Ik {{data.name}}</td>
                    <td>{{data.stats.count}}</td>
                    <td>{{data.stats.min}}</td>
                    <td>{{data.stats.max}}</td>
                    <td>{{data.stats.mean}}</td>
                    <td>{{data.stats.variance}}</td>
                    <td>{{data.stats.stdev}}</td>
                </tr>
            </tbody>
        </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Stats",
        data() {
            return {
                loaded: false,
                stats: null,
                fromDate: '',
                toDate: ''
            }
        },

        mounted() {
            this.getStats();
        },

        props: ['baseUrl', 'moduleId'],

        methods: {
            getStats() {
                axios.get('/api/modules/'+this.moduleId+'/moduleStats?from='+this.fromDate+'&to='+this.toDate)
                    .then(response => {
                        if (response.data.result === 'success') {
                            this.stats = response.data.data;
                            this.loaded = true;
                        } else {
                            this.stats.candidates = 0;
                            this.stats.first = '';
                            this.stats.last = '';
                            alert('Geen scores in tijdvak');
                        }
                    })
                    .catch(error => {
                        alert(error);
                    });
            },
        }
    }
</script>

<style scoped>
    .columns{
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: flex-start;
    }
</style>
